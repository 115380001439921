@use "../../styles/utils" as nw;

.Container {
  border-top: 1px solid var(--color-blue);
  padding: nw.fluid(38rem) 0;
  
  @include nw.media(">tablet") {
    margin-top: nw.fluid(60rem);
  }
}

.Inner,
.PrevNext,
.PageSelect {
  display: flex;
  align-items: center;
}

.Inner {
  & > p:first-child {
    display: none;

    @include nw.media(">tablet") {
      display: block;
    }
  }
}

.PrevNext {
  flex: 1 1 auto;
  justify-content: space-between;

  
  @include nw.media(">tablet") {
    justify-content: center;
    
    p {
      margin: 0 nw.fluid(75rem);
    }
  }

  .Button {
    width: nw.fluid(88rem);
  }
}

.PageSelect {
  display: none;

  @include nw.media(">tablet") {
    display: flex;
  }

  & > span {
    margin-left: nw.fluid(12rem);
  }
}