.Holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: #000;
  background-size: cover;
  background-position: center;
  will-change: transform;
  transform: translateZ(0);

  @media(pointer:fine) {
    position: absolute;
  }

  iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    transition: opacity 1s ease;
    background: #000;
    will-change: opacity;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
  }
}