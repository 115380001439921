@use "../../styles/utils" as nw;

.Container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.8);
  backdrop-filter: blur(10px);
  z-index: 9999;
}

.Player,
.Controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.Player {
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.Controls {
  display: flex;
  justify-content: flex-end;
  padding: var(--site-padding);
  filter: drop-shadow(0 0 nw.fluid(30rem) rgba(0, 0, 0, 0.25));
}