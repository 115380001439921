[data-scrollbar] {
  display: block;
  position: relative;
}

.scrollbar {
  &-content {
    transform: translate3d(0, 0, 0);
  }

  &-track {
    position: absolute;
    opacity: 0;
    z-index: 1;
    background: rgba(black, .25);
    user-select: none;
    transition: opacity 0.5s 0.5s ease-out;

    &.show,
    &:hover {
      opacity: 1;
      transition-delay: 0s;
    }
    
    &-y {
      top: 0;
      right: 0;
      width: 5px;
      height: 100%;
    }
  }

  &-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    background: var(--color-blue);
    border-radius: 0;
    cursor: pointer;
  }
}