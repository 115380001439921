@use "../../styles/utils" as nw;

.List {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 1.25em 0 0 -0.15em;
  font-size: nw.fluid(24rem);

  li {
    & + li {
      margin-left: 0.75em;
    }

    a {
      font-size: inherit;
      color: inherit;
      opacity: 0.35;
      transition: opacity 0.3s ease-out;
      min-width: nw.fluid(24rem);

      &:hover,
      &:active {
        opacity: 1;
      }
    }
  }
}