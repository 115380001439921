@use "../../styles/utils.scss" as nw;

.Container {
  display: grid;
  grid-template-columns: 1fr;

  @include nw.media(">tablet") {
    grid-template-columns: 1fr 2fr;
  }
  
  &:global(.flip) {
    @include nw.media(">tablet") {
      grid-template-columns: 2fr 1fr;

      .Photo {
        order: 1;
      }
    }
  }

  &:global(.expanded) {
    @include nw.media(">tablet") {
      .Content {
        padding-top: nw.fluid(140rem);
        padding-bottom: nw.fluid(150rem);
      }
    }
  }
}

.Photo {
  margin: 0;
  position: relative;
}

.PhotoInner {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 375px / 414px * 100%;
  background: #CCC;

  @include nw.media(">tablet") {
    padding: 0;
    height: 100%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.Content {
  padding: var(--site-padding);
  padding-bottom: nw.fluid(85rem);

  @include nw.media(">tablet") {
    padding: nw.fluid(60rem) nw.fluid(160rem) nw.fluid(70rem) nw.fluid(130rem);
  }
}

.Bio {
  padding-bottom: nw.fluid(85rem);

  h2 {
    @include nw.media(">tablet") {
      font-size: nw.fluid(40rem);
      line-height: 1em;
      margin: 0 auto 0.75em;
    }
  }

  p {
    margin-bottom: 3em;

    @include nw.media(">tablet") {
      margin-bottom: 2em;
    }
  }
}

.Info {
  display: grid;
  row-gap: nw.fluid(40rem);

  @include nw.media(">tablet") {
    grid-template-columns: 1fr 1fr;
    column-gap: nw.fluid(20rem);
  }

  & > div {
    border-top: 1px solid var(--color-blue);
    padding-top: nw.fluid(16rem);
    margin-right: nw.fluid(45rem);

    @include nw.media(">tablet") {
      margin-right: 0;
    }
  }

  p {
    & > span {
      display: block;
    }
  }
}

.ScrollContent {
  margin-top: nw.fluid(60rem);
  height: 100%;
  overflow: auto;

  @include nw.media('>tablet') {
    margin: 0;
    padding: 0 nw.fluid(76rem);
    padding-left: nw.fluid(115rem);
    min-height: nw.fluid(600rem);
    overflow: hidden;
    height: auto;
  }
}

.ExpandedBio {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: nw.fluid(20rem);
  
  @include nw.media('>tablet') {
    grid-template-columns: 38% 1fr;
    column-gap: nw.fluid(135rem);
    height: 100%;
  }

  & > div {
    &:first-of-type {
      img {
        width: 100%;
        height: auto;
      }
    }
    &:last-of-type {
      h3 {
        font-size: nw.fluid(40rem);
        font-weight: 500;
        margin-top: 0;
        text-transform: uppercase;
        letter-spacing: 0.25em;
        line-height: 1em;
      }

      p:first-of-type {
        margin-top: 0;
      }
    }
  }

  .BioContainer {
    height: 100%;
    flex-shrink: 1;
    overflow: hidden;
  }

  .ScrollView {
    overflow: auto;
    padding-right: nw.fluid(20rem);
    // flex-shrink: 1;
    // flex-grow: 0;

    // background-color: red;
  }
  
  .Contact {
    margin-top: nw.fluid(50rem);
    border-top: 1px solid var(--color-blue);
    padding-top: nw.fluid(20rem);

    span {
      display: block;
      line-height: 1.2em;
    }
  }
}