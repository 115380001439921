@use "../../styles/utils" as nw;

.Container {
  background: var(--color-white);
  color: var(--color-white);

  & > div {
    padding: nw.fluid(85rem) var(--site-padding);
    overflow: hidden;
    width: 100%;
  }

  header {
    display: flex;
    margin-bottom: nw.fluid(12rem);

    a {
      margin-left: nw.fluid(30rem);
    }
  }

  a {
    font-size: nw.fluid(18rem);
    line-height: 1em;
  }

  h3 {
    font-size: nw.fluid(18rem);
    line-height: 1.333em;
    margin: 0;
    color: var(--color-black);
  }
  
  :global {
    .slick-slider {
      margin-left: -24px;
      margin-right: -24px;
      padding-top: 35px;
      
      @include nw.media(">phonexl") {
        padding-left: 8rem;
      }

      @include nw.media(">tablet") {
        margin-left: -3rem;
        margin-right: -3rem;
        // padding-left: 10rem;
        padding-left: 0; // var(--site-padding);
        padding-top: nw.fluid(35rem);
      }
    }

    .slick-list {
      @include nw.media(">tablet") {
        padding: 0 var(--site-padding);
      }
    }

    .slick-arrow {
      left: 1rem;
      right: auto;
      top: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: nw.fluid(35rem);
      width: nw.fluid(35rem);
      // border: 1px solid var(--color-black);
      // border-radius: 50%;
      transform-origin: center;
      background: var(--color-white);
      z-index: 99;

      &.slick-disabled {
        opacity: 0.25;
      }

      &::before {
        content: unset;
      }
    }

    .slick-next {
      // transform: translate(0, calc(-50% + #{nw.fluid(28rem)}));
      transform: translate(0, calc(-50% + #{nw.fluid(24rem)}));
      right: 1rem;
      left: auto;

      @include nw.media(">maxwidth") {
        transform: translate(0, calc(-50% - #{nw.fluid(12rem)}));
      }
    }

    .slick-prev {
      // transform: translate(0, -50%) scaleX(-1);
      transform: translate(0, calc(-50% + #{nw.fluid(24rem)})) scaleX(-1);

      @include nw.media(">maxwidth") {
        transform: translate(0, calc(-50% - #{nw.fluid(12rem)})) scaleX(-1);
      }
    }

    .slick-slide {
      padding: 0 12px 0;

      @include nw.media(">phonexl") {
        width: nw.fluid(382rem);
        padding: 0 nw.fluid(9rem) 0;
      }
    }
  }
}

.Slide {
  display: flex;
  width: calc(100% - #{nw.fluid(20rem)});
  flex-direction: column;
  cursor: pointer;

  &:hover,
  &:active {
    z-index: 99;

    .ImageContainer img {
      box-shadow: (0 4px 8px rgba(0, 0, 0, 0.45));
    }

    .Title :global(p.title) {
      color: var(--color-blue);
      text-decoration: underline;

      svg {
        margin-left: 1em;
      }
    }
  }

  .ImageContainer {
    position: relative;
    height: 0;
    padding-top: 255px / 382px * 100%;
    overflow: visible;
    display: block;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: box-shadow 0.3s ease;
      box-shadow: 0 0 0 rgba(0,0,0,0);
    }
  }

  .Title {
    display: inline-flex;
    flex-direction: column;
    line-height: 1.2em;
    margin-top: nw.fluid(22rem);
    width: 100%;
    
    :global(p.title) {
      font-weight: 500;
      line-height: 1.333em;
      letter-spacing: 0.25em;
      text-transform: uppercase;

      position: relative;
      margin: 0;
      width: 85%;
      font-size: nw.fluid(18rem);

      @include nw.media(">tablet") {
        font-size: nw.fluid(24rem);
      }

      svg {
        position: absolute;
        vertical-align: middle;
        margin-left: 0.5em;
        bottom: 0.3em;
        transition: margin-left 0.15s ease;
        
        @include nw.media(">phonexl") {
          bottom: 0.4em;
        }
      }
    }

    :global(.eyebrow) {
      margin-top: 0;
      margin-bottom: nw.fluid(10rem);
    }
  }
}
