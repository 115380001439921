@use "../../styles/utils" as nw;

.Filters {
  display: flex;
  margin-top: nw.fluid(30rem);
  flex-direction: column;
  
  @include nw.media(">tablet") {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  & > p {
    margin-right: auto;
  }
}

.Select {
  & + .Select {
    margin-left: nw.fluid(15rem);
  }
}

.TotalPages {
  color: var(--color-white);
  margin-left: nw.fluid(10rem);
}

.MobileFilters {
  margin-top: nw.fluid(40rem);
}