@use "../../styles/utils" as nw;

.Container {
  position: relative;
  padding: nw.fluid(40rem) var(--site-padding);
  background: var(--color-black);
  z-index: 9;
}

.ThumbnailsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: nw.fluid(15rem);
  row-gap: nw.fluid(15rem);

  @include nw.media('>tablet') {
    grid-template-columns: repeat(5, 1fr);
  }

  & > div {
    height: 0;
    padding-top: 100%;
    position: relative;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.SlideShow {
  overscroll-behavior-y: contain;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-black);
  z-index: 9999;
  overflow: hidden;
}

.ImageTrack {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.ImageContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  will-change: transform;

  & > div {
    touch-action: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    will-change: transform;
  }
}

// .ImageContainer {
//   // position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;

//   img {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
//   }
// }

.Controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  padding: var(--site-padding);
  display: flex;
  justify-content: flex-end;
}