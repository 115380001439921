@use "../../styles/utils" as nw;

.Loading {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
}