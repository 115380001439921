@use "../../styles/utils" as nw;

.Container {
  position: relative;
  border-top: 1px solid var(--color-blue);

  span {
    display: block;
  }
  span + span {
    margin-top: nw.fluid(15rem);
  }
}