@use "../../styles/utils" as nw;

.Container {
  // overflow: visible;
  position: relative;
  flex-direction: column;
  min-height: 200vh;
  align-items: center;
  justify-content: center;
  // overflow: hidden;

  h1 {
    font-size: nw.fluid(40rem);
    font-weight: 500;
    line-height: 1.5em;
    text-transform: uppercase;
    color: #FEFEFE;
    letter-spacing: 0.25em;
    margin: 0;
    text-shadow: 0px 0px nw.fluid(25rem) rgba(0, 0, 0, 0.25);
    
    @include nw.media(">desktop") {
      font-size: nw.fluid(80rem);
      letter-spacing: 0.6em;
      line-height: 1.3125em;
    }
  }
}

.Title {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: nw.fluid(126rem) var(--site-padding) 0;
  display: flex;
  
  @include nw.media(">desktop") {
    padding: 0 nw.fluid(160rem);
    align-items: center;
  }
}

.TitleHeader {
  position: absolute;
  max-width: calc(100vw - #{nw.fluid(48rem)});

  @include nw.media(">desktop") {
    max-width: calc(100vw - #{nw.fluid(320rem)});
  }
}

.SeeMore {
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s ease, visibility 0s linear 0s;

  &:global(.hide) {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0s linear 0.2s;
  }
}