@use "../../styles/utils" as nw;

.Container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--site-padding);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  &:global(.lightbox) {
    background: rgba(black, 0.8);
  }

  :global(.nw-glass) {
    opacity: 1;
    z-index: -1;
    transition: none;
  }
}

.Content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CloseButton {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-blue);
  display: flex;
  font-size: nw.fluid(18rem);
  padding: nw.fluid(8rem);
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px var(--color-white);
  transition: background 0.15s ease, color 0.15s ease;

  @include nw.media(">tablet") {
    top: nw.fluid(20rem);
    right: nw.fluid(20rem);
  }

  &:hover,
  &:active {
    background: var(--color-white);
    color: var(--color-black);
  }

  &:focus {
    outline: none;
  }

  :global(.material-icons) {
    color: inherit;
    font-size: inherit;
  }
}