@use "../../styles/utils" as nw;

.Container {
  position: relative;
  padding: nw.fluid(85rem) var(--site-padding);
  flex-direction: column;
  background: var(--color-black);

  & > header {
    margin-bottom: nw.fluid(45rem);
  }
}

.Content {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: nw.fluid(70rem);
  
  @include nw.media(">tablet") {
    grid-template-columns: 0.53fr 1fr;
    column-gap: nw.fluid(133rem);
    padding: 0 nw.fluid(114rem);
  }

  :global(.large-body) {
    margin-bottom: nw.fluid(35rem);
    
    @include nw.media(">tablet") {
      margin-bottom: nw.fluid(70rem);
    }
  }
}

.AgentsImage {
  margin-left: calc(var(--site-padding) * -1);
  margin-right: calc(var(--site-padding) * -1);

  @include nw.media(">tablet") {
    width: calc(100% + nw.fluid(114rem) + var(--site-padding));
    margin-left: calc(nw.fluid(-114rem) - var(--site-padding));
    margin-right: 0;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.Links {
  position: relative;
  margin-top: nw.fluid(25rem);
}

.Agents {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: nw.fluid(20rem);
  margin-bottom: nw.fluid(130rem);
}

.Disclaimer {
  color: #6c6c6c;
  font-size: nw.fluid(11rem);
  line-height: 1.45em;
  margin-top: nw.fluid(50rem);
  margin-bottom: 0;
}