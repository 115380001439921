@use "../../styles/utils" as nw;

.About {
  position: relative;
  background: var(--main-bg-color);
  padding: nw.fluid(85rem) var(--site-padding);
  z-index: 1;

  & > header {
    margin-bottom: nw.fluid(45rem);

    h3 {
      font-size: nw.fluid(18rem);
      line-height: 1.333em;
    }
  }
}

.Bio {
  color: var(--color-white);
  font-size: nw.fluid(18rem);
  max-width: nw.fluid(400rem);
  margin-top: 0;
  margin-bottom: nw.fluid(40rem);
}

.Contact {
  @include nw.media(">tablet") {
    width: 66.66%;
    padding: 0 nw.fluid(120rem) 0 nw.fluid(112rem);
  }

  :global(p.title) {
    font-weight: 500;
    font-size: nw.fluid(32rem);
    line-height: 1.33em;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    margin: nw.fluid(45rem) auto nw.fluid(16rem);

    @include nw.media(">tablet") {
      font-size: nw.fluid(40rem);
    }
  }

  .tagline {
    font-size: nw.fluid(18rem);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    line-height: normal;
  }

  .Info {
    color: var(--color-white);
    display: grid;
    grid-template-columns: 1fr;
    row-gap: nw.fluid(40rem);
    margin-top: nw.fluid(85rem);
    font-size: nw.fluid(16rem);
    
    @include nw.media(">tablet") {
      grid-template-columns: 1fr 1fr;
      column-gap: nw.fluid(20rem);
      margin-top: nw.fluid(60rem);
    }

    & > div {
      border-top: 1px solid var(--color-blue);
    }

    :global(.contact-title) {
      font-size: inherit;
      font-weight: bold;
      margin: 1em auto;
      line-height: normal;
    }

    a {
      display: block;
    }

    address {
      font-style: normal;
    }

    span {
      display: block;
    }
    span + span {
      margin-top: nw.fluid(15rem);
    }
  }
}

.Disclaimer {
  color: #8e8e8e;
  font-size: nw.fluid(11rem);
  line-height: 1.45em;
  margin-top: nw.fluid(50rem);
  margin-bottom: 0;
}

.ImageContainer {
  position: relative;
  margin-left: -24px;
  margin-right: -24px;
  height: 0;
  overflow: hidden;
  padding-top: 375px / 414px * 100%;
  
  @include nw.media(">tablet") {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    width: 33.333%;
    height: auto;
  }
}

.NealImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;

  @include nw.media(">tablet") {
    
  }
}

.Footer {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--main-bg-color);
  height: nw.fluid(60rem);
  background: var(--color-white);
  z-index: 10;
  padding: nw.fluid(15rem) var(--site-padding);

  .Logo,
  .CompassLogo {
    height: nw.fluid(28rem);
    width: auto;

    path {
      fill: var(--main-bg-color);
    }
  }

  .CompassLogo {
    height: nw.fluid(16rem);
    margin-left: var(--site-padding);
  }

  .Copyright {
    text-transform: uppercase;
    font-size: nw.fluid(14rem);
    margin-left: auto;
  }
}