@use "../../styles/utils" as nw;

.Container {
  padding: nw.fluid(40rem) var(--site-padding) nw.fluid(80rem);
  flex-direction: column;
  background: var(--color-black);
  
  @include nw.media('>tablet') {
    padding: nw.fluid(80rem) var(--site-padding) nw.fluid(100rem);
    min-height: 100vh;
  }

  h4 {
    font-size: nw.fluid(24rem);
    font-weight: 500;
    line-height: 1.33em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: nw.fluid(30rem);
    letter-spacing: 0.25em;
    
    @include nw.media('>tablet') {
      display: none;
    }
  }
}

.Tab {
  color: #999;
  font-size: nw.fluid(18rem);
  font-weight: 700;
  line-height: 1.33em;
  text-transform: none;
  padding: 0;
  font-family: var(--font-primary);
  min-width: unset;

  & + & {
    margin-left: nw.fluid(55rem);
  }
  
  &:global(.Mui-selected) {
    color: var(--color-white);
  }
}

.TabContent {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: nw.fluid(20rem);
  padding-top: nw.fluid(38rem);
  padding-right: nw.fluid(110rem);
  border-top: 1px solid var(--color-blue);

  h3 {
    margin: 0;
    font-size: nw.fluid(40rem);
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0.25em;
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: nw.fluid(18rem);

    & > li {
      padding: nw.fluid(28rem) 0;

      &:first-child {
        padding-top: 0;
      }

      & + li {
        border-top: 1px solid #444;
      }
    }
  }
}