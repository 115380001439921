@use "../../styles/utils" as nw;

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-size: nw.fluid(10rem);
  font-weight: 700;
  text-transform: uppercase;
  line-height: nw.fluid(40rem);
  letter-spacing: 0.1em;
  padding: 0 nw.fluid(16rem);
  box-shadow: inset 0 0 0 1px var(--color-blue);
  transition:
    box-shadow 0.2s ease,
    color 0.2s ease 0.1s;

  &:hover,
  &:active {
    box-shadow:
      inset 0 0 0 1px var(--color-blue),
      inset 0 nw.fluid(-40rem) 0 1px var(--color-blue);
  }

  &:focus {
    outline: none;
  }

  &:global(.hide) {
    visibility: hidden;
  }

  :global {
    .material-icons {
      font-size: 1em;
      margin-left: 0.3em;
    }
  }

  &[disabled] {
    box-shadow: inset 0 0 0 1px #444;
    color: #999;
    pointer-events: none;

    svg {
      opacity: 0.4;
    }
  }

  &[data-variant="light"] {
    color: var(--color-black);
    transition: box-shadow 0.2s ease, color 0.2s ease;

    &:hover,
    &:active {
      color: var(--color-white);
    }
  }

  &[data-variant="text"] {
    color: var(--color-blue);
    box-shadow: none;
    font-size: nw.fluid(16rem);
    padding: 0;
    font-weight: normal;
    line-height: 1.625em;
  }

  &[data-variant="icon"] {
    padding: 0;
    box-shadow: none;
    font-size: nw.fluid(30rem);

    transition: color 0.2s ease;

    :global {
      .material-icons,
      .material-icons-outlined {
        margin: 0;
        font-size: inherit;
      }
    }

    svg > path {
      transition: stroke 0.2s ease;
    }

    &:hover,
    &:active {
      color: var(--color-blue);

      svg {
        path {
          stroke: var(--color-blue);
        }
      }
    }
  }

  &[data-variant="icon-circle"] {
    border: 1px solid var(--color-white);
    padding: 0;
    width: nw.fluid(35rem);
    height: nw.fluid(35rem);
    justify-content: center;
    color: var(--color-blue);
    border-radius: 50%;

    :global {
      .material-icons,
      .material-icons-outlined {
        margin: 0;
        font-size: nw.fluid(18rem);
      }
    }
  }
}