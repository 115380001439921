@use "../../styles/utils" as nw;

.Container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: nw.fluid(40rem) var(--site-padding) nw.fluid(60rem);
  
  @include nw.media(">tablet") {
    padding: nw.fluid(85rem) var(--site-padding) nw.fluid(115rem);
  }

  h2 {
    margin: 0;
    font-size: nw.fluid(24rem);
  }

  :global {
    .slick-slider {
      margin-top: nw.fluid(50rem);
      margin-left: -24px;
      margin-right: -24px;

      @include nw.media(">phonexl") {
        margin-left: -3rem;
        margin-right: -3rem;
      }
    }

    .slick-track {
      display: flex;
      height: nw.fluid(400rem);
    }

    .slick-slide {
      position: relative;
      padding: 0 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      @include nw.media(">phonexl") {
        padding: 0 3rem;
      }

      & > div {
        height: 100%;
      }
    }

    .slick-arrow {
      left: 50%;
      right: auto;
      top: calc(100% + #{nw.fluid(10rem)});
    }

    .slick-prev {
      transform: translateX(calc(-50% - #{nw.fluid(28rem)})) scaleX(-1);
    }

    .slick-next {
      transform: translateX(calc(-50% + #{nw.fluid(28rem)}));
    }

    .slick-disabled {
      opacity: 0.25;
    }
  }
}

.DownloadPDF {
  position: absolute;
  right: var(--site-padding);
  bottom: var(--site-padding);
  border: 1px solid var(--color-black);
  border-radius: 50%;
  color: var(--color-blue);
  font-size: nw.fluid(18rem);
  padding: 0;
  display: flex;
  width: nw.fluid(30rem);
  height: nw.fluid(30rem);
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease, color 0.2s ease;

  :global(.material-icons) {
    font-size: inherit;
    margin: 0;
  }

  &:hover,
  &:active {
    background: var(--color-black);
    color: var(--color-white);
    text-decoration: none;
  }

  @include nw.media(">tablet") {
    bottom: nw.fluid(60rem);
  }
}

.ImageWrapper {
  cursor: pointer;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
  }

  :global(.material-icons) {
    position: absolute;
    display: flex;
    border: 1px solid var(--color-black);
    border-radius: 50%;
    top: 0;
    right: nw.fluid(10rem);
    font-size: nw.fluid(24rem);
    color: var(--color-blue);
    width: nw.fluid(35rem);
    height: nw.fluid(35rem);
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover,
  &:active {
    :global(.material-icons) {
      opacity: 1;
    }
  }
}

.Loading {
  position: fixed;
  top: 0;
  right: 0;
  color: var(--color-white);
}