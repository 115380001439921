@use "../../styles/utils" as nw;

.Container {
  position: relative;
  display: flex;
  align-items: center;
  width: nw.fluid(435rem);
  height: nw.fluid(40rem);
  margin-top: nw.fluid(40rem);

  :global(.input-group) {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .SearchIcon {
    display: inline-flex;

    svg {
      width: nw.fluid(20rem);
      height: auto;
    }
  }

  input[type="text"] {
    background: none;
    border: none;
    color: var(--color-white);
    outline: 0;
    flex: 1 0 auto;
    line-height: nw.fluid(40rem);
    font-size: inherit;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-family: futura-pt, sans-serif;
    padding-left: nw.fluid(30rem);

    &:focus {
      outline: 0;
    }
  }
}

.Wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: nw.fluid(14rem);
  border-bottom: 1px solid var(--color-blue);
}

.ClearSearch {
  color: var(--color-blue);
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.9);
  z-index: 9999;
  padding: nw.fluid(100rem) var(--site-padding) 0;
  
  @include nw.media(">tablet") {
    padding: 25vh nw.fluid(275rem) 0;
  }

  .Container {
    width: 100%;
    margin: 0;
    height: auto;
    
    input[type="text"] {
      font-size: nw.fluid(14rem);
      line-height: nw.fluid(40rem);
      
      @include nw.media(">tablet") {
        font-size: nw.fluid(24rem);
        line-height: nw.fluid(65rem);
      }
    }
  }
}

.Results {
  list-style: none;
  color: var(--color-white);
  padding-left: nw.fluid(35rem);
  font-size: nw.fluid(14rem);
  letter-spacing: 0.15em;
  margin: 0;
  margin-top: nw.fluid(15rem);
  
  @include nw.media(">tablet") {
    font-size: nw.fluid(24rem);
  }

  li {
    line-height: nw.fluid(32rem);
    padding: 0 nw.fluid(15rem);
    cursor: pointer;
    text-transform: uppercase;
    
    @include nw.media(">tablet") {
      line-height: nw.fluid(55rem);
    }

    &:global(.selected) {
      background: #444;
    }

    &:global(.no-match) {
      opacity: 0.5;
      pointer-events: none;
    }

    & + li {
      margin-top: nw.fluid(10rem);

      @include nw.media(">tablet") {
        margin-top: 0;
      }
    }
  }
}

.ResultItem {
  position: relative;
}