@use "../../styles/utils" as nw;

.Container {
  position: relative;
  padding: nw.fluid(85rem) var(--site-padding) nw.fluid(100rem);
  
  &:global(.theme-dark) {
    background-color: var(--color-black);
    color: var(--color-white);
  }

  &:global(.large-title) {
    h2 {
      font-size: nw.fluid(24rem);
      text-transform: uppercase;
      letter-spacing: 0.25em;
      font-weight: bold;
      line-height: normal;
      
      @include nw.media(">tablet") {
        font-size: nw.fluid(36rem);
      }
    }
  }

  :global {
    p.large-body {
      margin-top: nw.fluid(40rem);
      margin-bottom: 0;

      @include nw.media(">tablet") {
        padding: 0 nw.fluid(120rem);
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}