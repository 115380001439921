@use "../../styles/utils" as nw;

.SeeMore {
  position: absolute;
  display: none;
  justify-content: center;
  height: nw.fluid(75rem);
  bottom: nw.fluid(20rem);
  color: #FEFEFE;
  font-size: nw.fluid(12rem);
  text-transform: uppercase;
  font-weight: 500;
  transition: padding-top 0.2s ease;

  @include nw.media(">tablet") {
    display: inline-flex;
  }

  &::after {
    content: "";
    position: absolute;
    top: nw.fluid(25rem);
    width: 1px;
    height: nw.fluid(50rem);
    background: var(--color-blue);
    transition: transform 0.2s ease;
  }

  &:hover,
  &:active {
    padding-top: nw.fluid(5rem);

    &::after {
      transform: translateY(nw.fluid(12rem));
    }
  }

  &:focus-visible {
    outline: 1px solid white;
  }
}