@use "../../styles/utils" as nw;

.Details {
  padding: 0;
  display: flex;
  min-height: 100vh;
  
  @include nw.media(">desktop") {
    height: 100vh;
    align-items: center;
    padding: 0 var(--site-padding);
  }
}

.DetailsContent {
  background: rgba(#555, 0.3);
  padding: nw.fluid(35rem) var(--site-padding) nw.fluid(45rem);
  justify-content: space-between;
  width: 100%;
  color: #FEFEFE;
  backdrop-filter: blur(20px) brightness(90%) contrast(110%);
  
  @include nw.media(">tablet") {
    padding: nw.fluid(75rem) nw.fluid(115rem) nw.fluid(180rem);
    max-height: 95vh;
    overflow: hidden;
  }
}

.DetailHeader {
  margin-bottom: nw.fluid(30rem);
  
  @include nw.media(">tablet") {
    margin-bottom: nw.fluid(75rem);
  }

  h2 {
    font-size: nw.fluid(24rem);
    margin: 0;
  }
}

.DetailBody {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: nw.fluid(20rem);
  font-size: nw.fluid(16rem);
  line-height: 1.5625em;
  
  @include nw.media(">tablet") {
    grid-template-columns: 2fr 1fr;
    column-gap: nw.fluid(133rem);
    // margin-bottom: nw.fluid(75rem);
  }

  p {
    margin: 0;
  }

  .PropertyDescription {
    .Expandable {
      max-height: 7.8125em;
      overflow: hidden;

      @include nw.media(">tablet") {
        max-height: 33vh;
      }

      & + button {
        margin-top: nw.fluid(30rem);
      }
    }

    &:global(.show-more) {
      .Expandable {
        max-height: unset;
      }
    }

    :global(.scroll-content) {
      padding-right: nw.fluid(15rem);
    }
  
    p + p {
      margin-top: 1em;
    }
  }
}

.DetailSpecs {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  overflow: auto;

  li {
    // line-height: nw.fluid(58rem);
    padding: nw.fluid(20rem) 0;
    align-items: center;
    line-height: 1.4em;
    display: grid;
    grid-template-columns: 1fr 1fr;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    & + li {
      border-top: 1px solid rgba(#FEFEFE, 0.35);
    }

    & > span:first-of-type {
      font-size: nw.fluid(10rem);
      text-transform: uppercase;
    }
  }
}

.DetailActions {
  display: flex;
  justify-content: flex-end;
  
  @include nw.media(">tablet") {
    position: absolute;
    justify-content: flex-start;
    bottom: nw.fluid(85rem);
    left: nw.fluid(115rem);
    right: nw.fluid(115rem);
  }

  button + button {
    margin-left: 30px;
  }
}

.ReadMoreModal {
  padding: var(--site-padding);
  padding-top: nw.fluid(80rem);
  height: 100%;

  @include nw.media(">tablet") {
    padding: nw.fluid(80rem);
  }

  p:first-of-type {
    margin-top: 0;
  }
}

.ReadMoreText {
  overflow: auto;
  height: 100%;

  :global(.scroll-content) {
    padding-right: nw.fluid(20rem);
    
    @include nw.media(">tablet") {
      column-count: 2;
    }
  }
}