@use "../../styles/utils" as nw;

.Container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
}

.ImageContainer,
.ImageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ImageWrapper {
  display: flex;
  justify-content: center;
  will-change: opacity;

  img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    transform-origin: left center;
    z-index: 1;
  }

  &:global(.portrait) {
    img {
      object-fit: contain;
    }
  }
}

.ImageBlurred {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 1rem);
  height: calc(100% + 1rem);
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 0;
  filter: blur(nw.fluid(8rem)) grayscale(100%) brightness(0.4);
}

.PrevNext {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  :global {
    .prev,
    .next {
      position: absolute;
      top: 0;
      height: 100%;
      width: 20%;
      cursor: pointer;
    }
    .prev {
      left: 0;
    }
    .next {
      right: 0;
    }
  }
}

.Controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--site-padding);
  padding-top: 0;
  z-index: 10;
}

.Navigation {
  display: flex;
  align-items: center;
  margin: 0 auto;
  filter: drop-shadow(0 0 nw.fluid(20rem) rgba(0, 0, 0, 0.35));

  p {
    margin: 0 nw.fluid(16rem);
    font-size: nw.fluid(14rem);
    font-weight: 700;
  }

  .PrevButton,
  .NextButton {
    padding: nw.fluid(15rem) 0;
    svg {
      width: nw.fluid(25rem);
      height: auto;
    }
  }

  .PrevButton {
    svg {
      transform: rotate(180deg);
    }
  }
}

.Caption,
.Thumbnails {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: nw.fluid(400rem);
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 54.39%);
}

.Caption {
  align-items: center;
  
  & > p {
    margin: 5% 15vw 0;
  }
}

.ThumbnailsWrapper {
  position: absolute;
  display: flex;
  width: 100%;
  padding: 0 var(--site-padding);
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  bottom: nw.fluid(105rem);

  & > div {
    width: nw.fluid(94rem);
    height: nw.fluid(64rem);
    padding: nw.fluid(7rem);
    cursor: pointer;
    transition: box-shadow 0.26s ease;

    &:global(.active) {
      & > img {
        opacity: 1;
      }
    }

    &:hover,
    &:active {
      box-shadow: inset 0 0 0 4px var(--color-blue);
      & > img {
        opacity: 1;
      }
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;;
      opacity: 0.45;
      transition: opacity 0.26s ease;
    }
  }
}
