@use "../../styles/utils" as nw;

$p8: nw.fluid(8rem);

.Nav {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: nw.fluid(44rem) var(--site-padding) 0;
  z-index: 10;

  &:global(.shrink) {
    background: var(--color-white);
    padding: nw.fluid(16rem) nw.fluid(20rem);
    
    @include nw.media(">tablet") {
      width: auto;
    }

    .Brand {
      svg {
        height: nw.fluid(35rem);

        path {
          fill: var(--color-black);
        }
      }
    }

    .Hamburger {
      @include nw.media(">tablet") {
        margin-left: nw.fluid(20rem);
      }

      & > span {
        background: var(--color-black);
      }
    }

    .BackButton {
      color: var(--color-black);
      display: none;
    }

    .CoListLogos {
      border-right-color: var(--color-black);

      a img {
        height: nw.fluid(35rem);
      }
    }
  }
}

.Brand {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid white;
  }

  svg {
    height: nw.fluid(44rem);
    width: auto;

    path {
      fill: var(--color-white);
      transition: fill 0.26s ease;
    }
  }
}

.Hamburger {
  cursor: pointer;
  padding: $p8;
  background: none;
  border: none;
  margin-left: auto;
  z-index: 10;
  overflow: hidden;
  order: 2;
  
  @include nw.media(">tablet") {
    margin-left: nw.fluid(40rem);
    order: unset;
  }

  &:focus-visible {
    outline: 1px solid white;
  }

  & > span {
    display: block;
    width: nw.fluid(24rem);
    height: nw.fluid(3rem);
    background: var(--color-white);
    transition: transform 0.2s ease, background-color 0.2s ease, margin 0.2s ease, width 0.2s ease;

    &:last-child {
      width: nw.fluid(16rem);
    }

    & + span {
      margin-top: nw.fluid(4rem);
    }
  }

  &:hover,
  &:active {
    & > span {
      &:first-child {
        width: nw.fluid(16rem);
      }
      &:last-child {
        width: nw.fluid(24rem);
      }
    }
  }

  &.open {
    & > span {
      transform: translateX(calc(-100% - #{$p8}));
    }
  }
}

.BackButton {
  display: inline-flex;
  align-items: center;
  margin-left: nw.fluid(24rem);
  font-size: nw.fluid(16rem);
  line-height: 1em;
  padding: nw.fluid(4rem) nw.fluid(8rem);
  transition: color 0.2s ease, margin 0.2s ease;
  
  &:hover,
  &:active {
    margin-left: nw.fluid(20rem);
    color: var(--color-blue);
    background: var(--color-white);

    :global(.material-icons) {
      margin-right: nw.fluid(12rem);
    }
  }

  &:focus {
    outline: none;
  }

  :global(.material-icons) {
    font-size: nw.fluid(20rem);
    margin-right: nw.fluid(8rem);
    transition: margin 0.2s ease;
  }
}

.CoListLogos {
  display: flex;
  margin-right: nw.fluid(36rem);
  border-right: 1px solid var(--color-white);
  padding-right: nw.fluid(36rem);

  a {
    display: flex;

    img {
      height: nw.fluid(44rem);
      width: auto;
    }
  }

  &:empty {
    display: none;
  }
}
