.Curtain {
  position: fixed;
  top: 0;
  left:0;
  width: 100vw;
  height: 100vh;
  background: #FEFEFE;
  z-index: 9999;
  pointer-events: none;
  transform-origin: left center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Logo {
  height: 75px;
  width: auto;

  path {
    fill: black;
  }
}