@use "../../styles/utils" as nw;

.Container {
  width: nw.fluid(80rem);
  height: nw.fluid(80rem);
  background: var(--color-white);
  border-radius: 50%;
  filter: drop-shadow(0 0 nw.fluid(30rem) rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:active {
    background: var(--color-black);
    svg {
      transform: scaleY(-1);
      path {
        stroke: var(--color-white);
        fill: var(--color-white);
      }
    } 
  }
  
  svg {
    transform: scaleY(1);
    transform-origin: center;
    transition: transform 0.3s ease;
    margin-left: nw.fluid(8rem);

    path {
      transition: fill 0.3s ease;
      fill: var(--color-white);
      stroke: var(--color-black);
    }
  }
}