@use "../../styles/utils" as nw;

.Select {
  position: relative;
  min-width: nw.fluid(200rem);
  color: var(--color-white);
  background: var(--color-black);

  &:global(.small) {
    min-width: nw.fluid(75rem);
  }
  
  :global {
    .nwp-select {
      &__control {
        background: var(--color-black);
        border: 1px solid #999;
        border-radius: 0;
        box-shadow: none;
        min-height: nw.fluid(38rem);
  
        &--is-focused {
          border-color: var(--color-blue);
        }
      }
  
      &__value-container {
        padding: nw.fluid(2rem) nw.fluid(8rem);
        padding-left: nw.fluid(13rem);
      }
  
      &__placeholder,
      &__single-value,
      &__option {
        color: var(--color-white);
        font-size: nw.fluid(10rem);
        font-weight: 700;
        line-height: 1.4em;
        text-transform: uppercase;
        letter-spacing: 0.01em;
      }

      &__placeholder,
      &__single-value {
        position: relative;
        transform: none;
        top: auto;
      }
  
      &__indicators {
        padding-right: nw.fluid(12rem);
  
        :global(.material-icons) {
          font-size: nw.fluid(18rem);
          color: var(--color-blue);
        }
      }
  
      &__menu {
        background: var(--color-black);
        border-radius: 0;
        border: 1px solid var(--color-blue);
        border-top-color: rgba(var(--color-blue-rgb), 0.4);
        margin: 0;
        top: calc(100% - 1px);
        padding: 0;
        z-index: 99;
      }
  
      &__menu-list {
        padding: 0;
        max-height: unset;
      }
  
      &__option {
        padding: nw.fluid(12rem) nw.fluid(15rem);
        color: #999;
        font-weight: 500;
  
        &--is-focused,
        &--is-selected,
        &:active {
          background: #444;
          color: var(--color-white);
        }
      }
    }
  }
}

.Selected {
  font-size: nw.fluid(10rem);
  font-weight: 500;
  line-height: 1.4em;
  text-transform: uppercase;
  padding: nw.fluid(12rem) nw.fluid(16rem);
  border: 1px solid var(--color-blue);
  display: flex;
  align-items: center;
  cursor: default;

  :global(.material-icons) {
    font-size: nw.fluid(16rem);
    margin-left: auto;
  }
}

.Options {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  width: 100%;
  border: 1px solid var(--color-blue);
  border-top-color: rgba(black, 0.6);
  z-index: 9999;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      color: #999;
      text-transform: uppercase;
      font-size: nw.fluid(10rem);
      line-height: 1.4em;
      font-weight: 500;
      padding: nw.fluid(12rem) nw.fluid(16rem);
      cursor: default;

      &:hover,
      &:active {
        color: var(--color-white);
        background: #444;
      }

      & + li {
        border-top: 1px solid #333;
      }
    }
  }
}

.ClearIndicator {
  margin-right: nw.fluid(5rem);
  cursor: pointer;
  color: var(--color-white) !important;
  transition: color 0.2s ease;
  
  &:hover,
  &:active {
    color: var(--color-blue) !important;
  }
}
