@use "./styles/utils" as nw;

.App {
  width: 100%;
}

.LazyLoading {
  background: var(--color-black);
  color: var(--color-white);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}