@use "utils" as nw;

%input-field {
  font-family: var(--font-primary);
  width: 100%;
  color: var(--color-white);
  background: none;
  border: none;
  box-shadow: inset 0 0 0 1px #999;
  text-transform: uppercase;
  font-size: nw.fluid(10rem);
  line-height: 1.4em;
  letter-spacing: 0.15em;
  padding: 0 nw.fluid(15rem);
  height: nw.fluid(40rem);

  &:-internal-autofill-selected {
    background-color: none !important;
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px var(--color-blue);
  }

  @include nw.media(">tablet") {
    font-size: nw.fluid(14rem);
    padding: nw.fluid(30rem) nw.fluid(25rem);
    letter-spacing: 0.21em;
    height: auto;

    &.small {
      padding: nw.fluid(20rem) nw.fluid(25rem);
      height: nw.fluid(60rem);
    }
  }
}

%submit-button {
  border-radius: 0;
  border: none;
  background: none;
  color: var(--color-white);
  text-transform: uppercase;
  box-shadow: inset 0 0 0 1px var(--color-blue);
  font-family: var(--font-primary);
  font-size: nw.fluid(10rem);
  font-weight: 700;
  line-height: nw.fluid(40rem);
  letter-spacing: 0.01em;
  padding: 0 nw.fluid(16rem);
  cursor: pointer;
  transition: background 0.2s ease;

  &.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: nw.fluid(40rem);
    width: nw.fluid(40rem);
    background: var(--color-blue);

    .material-icons {
      font-size: nw.fluid(26rem);
    }
  }

  &:hover,
  &:active {
    background: var(--color-blue);
  }

  &:focus {
    outline: none;
  }

  @include nw.media(">tablet") {
    font-size: nw.fluid(14rem);
    line-height: nw.fluid(80rem);
    letter-spacing: 0.21em;
    padding: 0 nw.fluid(60rem);
    
    &.small {
      line-height: nw.fluid(60rem);
      padding: 0 nw.fluid(30rem);
    }

    &.icon {
      width: nw.fluid(60rem);
      height: nw.fluid(60rem);

      .material-icons {
        font-size: nw.fluid(36rem);
      }
    }
  }
}

.hs-form {
  &-field {
    margin-bottom: nw.fluid(18rem);
    
    @include nw.media(">tablet") {
      margin-bottom: nw.fluid(24rem);
    }

    label { display: none; }

    .input {
      input,
      textarea,
      select {
        @extend %input-field;
      }

      textarea {
        padding: nw.fluid(15rem);
        height: nw.fluid(200rem);

        @include nw.media(">tablet") {
          padding: nw.fluid(30rem) nw.fluid(25rem);
        }
      }
    }

    &.hs-recaptcha {
      display: none;
    }
  }

  .hs-fieldtype-select {
    position: relative;
        
    &::after {
      content: "keyboard_arrow_down";
      font-family: "Material Icons";
      font-feature-settings: 'liga';
      font-size: nw.fluid(20rem);
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(#{nw.fluid(40rem)} - 2px);
      height: calc(#{nw.fluid(40rem)} - 2px);
      background: var(--color-black);
      position: absolute;
      top: 1px;
      right: 1px;
      color: var(--color-blue);
      pointer-events: none;

      @include nw.media(">tablet") {
        font-size: nw.fluid(40rem);
        width: calc(#{nw.fluid(80rem)} - 2px);
        height: calc(#{nw.fluid(78rem)} - 2px);
      }
    }
  }

  .hs-error-msgs {
    list-style: none;
    margin: 0;
    padding: 0;
    background: var(--color-blue);

    li {
      padding: nw.fluid(5rem) nw.fluid(10rem);

      & > label {
        display: block;
      }
    }
  }

  .hs_error_rollup {
    margin-bottom: nw.fluid(24rem);
  }

  .hs-submit {
    input[type="submit"] {
      @extend %submit-button;
    }
  }
}

.hs-main-font-element.submitted-message {
  p {
    font-size: nw.fluid(32rem);
    line-height: 1.5em;
    letter-spacing: 0.05em;
    margin-top: 0;
  }
}

.nwp-form {
  .form-group {
    display: block;

    & + .form-group {
      margin-top: nw.fluid(20rem);
    }
  }

  .form-control {
    input[type="password"] {
      @extend %input-field;
    }

    button[type="submit"] {
      @extend %submit-button;
    }
  }

  &.inline {
    display: flex;

    .form-group + .form-group {
      margin-top: 0;
    }
  }
}